import React from 'react';
import env from '../../utils/env';

const handleReturnHomeClick = () => {
  window.location = env.envURL;
};

const handleCustomerSupportClick = () => {
  window.location = 'https://support.fabfitfun.com/en/articles/44-how-can-i-contact-customer-care';
};

const ValidCookieForm = ({ isLooping }) => (
  <div className="login-page mar-a">
    <div className="overlay">
      {isLooping && (
        <div className="valid-cookie-form">
          <form>
            <div className="form-content">
              <div>
                <span className="title-header">
                  Oops, Something Went Wrong.
                </span>
              </div>
              <div className="title-body">
                <span className="title-body-span">
                  Try again later, or reach out to <br />
                  Customer Service if you need help.
                </span>
              </div>
            </div>
            <button
              className="button return-home"
              type="button"
              onClick={handleReturnHomeClick}
            >
              <span className="button-text return">RETURN HOME</span>
            </button>
            <button
              className="button customer-support"
              type="button"
              onClick={handleCustomerSupportClick}
            >
              <span className="button-text">Customer Support</span>
            </button>
          </form>
        </div>
      )}
    </div>
  </div>
);

export default ValidCookieForm;
