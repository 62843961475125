import React, { Component } from 'react';
import { logout, getGetTheBoxUrl } from '../../utils/data';
import { gotoPageAfterXSecond } from '../../utils/commons';
import './Logout.css';
import brandLogo from './img/brand-logomark.svg';

class Logout extends Component {
  componentWillMount() {
    logout((res) => {
      gotoPageAfterXSecond({
        goto_url: getGetTheBoxUrl(),
      });
    });
  }

  render() {
    return (
      <div className="container">
        <div className="loadingIconContainer">
          <img
            src={brandLogo}
            className="loadingIcon"
            alt="brand logo loading"
          />
        </div>
      </div>
    );
  }
}

export default Logout;
