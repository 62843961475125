export function isValidPasswordLength(password) {
  return password.length >= 8 && password.length <= 50;
}

export function isValidMinPasswordLength(password) {
  return password.length >= 8;
}

export function isValidMaxPasswordLength(password) {
  return password.length <= 50;
}

export function isContainUpperAndLowerCase(password) {
  return isContainLowerCase(password) && isContainUpperCase(password);
}

export function isContainLowerCase(password) {
  var lowerCaseRe = /[a-z]+/;
  return lowerCaseRe.test(password);
}

export function isContainUpperCase(password) {
  var upperCaseRe = /[A-Z]+/;
  return upperCaseRe.test(password);
}

export function isContainNumber(password) {
  var re = /[0-9]+/;
  return re.test(password);
}

export function isContainSpecialCharacter(password) {
  var re = /[\W_]+/;
  return re.test(password);
}
