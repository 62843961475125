import env from './env';
import Cookies from 'universal-cookie';

const LOGIN_COUNT = 'login_count';
const cookies = new Cookies();

export function isLoop() {
  let count = cookies.get(LOGIN_COUNT);
  if (count) {
    count = parseInt(count, 10);
    count++;
  } else {
    count = 0;
  }
  cookies.set(LOGIN_COUNT, count, { maxAge: 60, path: '/' });

  return count >= 10;
}

export function gotoPageAfterXSecond(params) {
  if (typeof params != 'undefined' && params.hasOwnProperty('goto_url')) {
    setTimeout(function () {
      window.location = params['goto_url'];
    }, 2000);
  }
}

export function checkNested(obj /*, level1, level2, ... levelN*/) {
  var args = Array.prototype.slice.call(arguments, 1);

  for (var i = 0; i < args.length; i++) {
    if (!obj || !obj.hasOwnProperty(args[i])) {
      return false;
    }
    obj = obj[args[i]];
  }
  return true;
}

export function sanitizeUrl(url) {
  if (containHostName(url)) {
    url = addHttpsIfMissing(url);
  }

  try {
    new URL(url);
  } catch (_) {
    //invalid URL, add default domain
    let slash = '';
    if (url.charAt(0) !== '/') {
      slash = '/';
    }

    url = env.envURL + slash + url;
  }
  return url;
}

export function validOrigin(origin) {
  try {
    return new URL(origin).host.endsWith(env.originURL);
  } catch (_) {
    return false;
  }
}

export function scrollTopInDesktopMode() {
  if (window.innerWidth >= 760) {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }
}

function containHostName(origin) {
  let pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  ); // fragment locator
  return !!pattern.test(origin);
}

function addHttpsIfMissing(url) {
  if (!/^(?:f|ht)tps?\:\/\//.test(url)) {
    url = 'https://' + url;
  }
  return url;
}
