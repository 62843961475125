import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import ldRedux from '@svey/ld-redux';

import userReducer from './userReducer';
import viewReducer from './viewReducer';

export default combineReducers({
  user: userReducer,
  view: viewReducer,
  routing: routerReducer,
  launchDarkly: ldRedux.reducer(),
});
