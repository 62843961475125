import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Route, withRouter } from 'react-router-dom';

import { GlobalFooter } from '@fff-web/fff-global-footer';
import { ProspectGlobalNav } from '@fff-web/fff-global-nav';

import { setViewWidth } from './actions/viewActions';

import Home from '../src/Components/Home/Home';
import ResetPassword from '../src/Components/ResetPassword/ResetPassword';
import Otp from '../src/Components/Otp/Otp';
import WebOtpVerify from '../src/Components/Otp/WebOtpVerify';
import WebOtp from '../src/Components/Otp/WebOtp';
import Logout from '../src/Components/Logout/Logout';

import './App.css';
import './globals.css';
import './Shared/fontAwesome/css/fontawesome.min.css';
import './Shared/fontAwesome/css/light.min.css';
import './Shared/fontAwesome/css/regular.min.css';
import './Shared/fontAwesome/css/solid.min.css';

import headerRoutes from './utils/headerRoutes';
import env from './utils/env';

class App extends Component {
  componentWillMount() {
    window.addEventListener('resize', this.handleResize);
  }

  handleCTA = () => {
    window.location.href = `${env.envURL}/get-the-box`;
  };

  handleResize = () => this.props.setViewWidth(window.innerWidth);

  render() {
    const isLogoutPage =
      ['/logout'].indexOf(this.props.location.pathname) === 0;
    return (
      <div className="App">
        {!isLogoutPage ? (
          //isLoginPage is always true because is under the subdomain login
          <ProspectGlobalNav handleCTA={this.handleCTA} isLoginPage />
        ) : (
          <div className="loadingBlur">
            <ProspectGlobalNav handleCTA={this.handleCTA} isLoginPage />
          </div>
        )}
        <main className="add-ons">
          <Route path="/lost-password" component={ResetPassword} />
          <Route path="/otp" component={Otp} />
          <Route path="/web-otp" component={WebOtp} />
          <Route path="/web-otp-verify" component={WebOtpVerify} />
          <Route path="/" exact component={Home} />
          <Route path="/logout" exact component={Logout} />
        </main>
        {!isLogoutPage && <GlobalFooter isUseAnalytic={false} />}
      </div>
    );
  }
}

const mS = (state) => ({
  isMobile: state.view.isMobile,
});

const mD = (dispatch) =>
  bindActionCreators(
    {
      setViewWidth,
    },
    dispatch
  );

export default withRouter(connect(mS, mD)(App));
