export const USER = {
  SET_USER_DATA: 'user/set_user_data',
  SET_USERNAME: 'loginForm/set_username',
  SET_PASSWORD: 'loginForm/set_password',
  SET_PASSWORD_1_RESET_FORM: 'resetForm/set_password_1',
  SET_PASSWORD_2_RESET_FORM: 'resetForm/set_password_2',
};

export const setUserData = (payload) => ({
  type: USER.SET_USER_DATA,
  payload: payload,
});

export const setUsername = (str) => ({
  type: USER.SET_USERNAME,
  payload: str,
});

export const setPassword = (str) => ({
  type: USER.SET_PASSWORD,
  payload: str,
});

export const setPassword1InResetForm = (str) => ({
  type: USER.SET_PASSWORD_1_RESET_FORM,
  payload: str,
});

export const setPassword2InResetForm = (str) => ({
  type: USER.SET_PASSWORD_2_RESET_FORM,
  payload: str,
});
