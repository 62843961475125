import Cookies from 'universal-cookie';
import { UAParser } from 'ua-parser-js';
import {
  identify,
  trackEvent,
  trackPageLoad,
  trackElement,
} from '@fff-web/fff-analytics';

const cookies = new Cookies();

export const segmentEnum = {
  LoggedIn: 'Logged In',
  CreateNewPassword: 'Create New Password',
  SendResetPasswordEmail: 'Send Reset Password Email',
  AutoLoggedIn: 'Auto-logged In Updating Password',
  AutoLoginLink: 'Login',
};

/*
 * catching any errors in the imported track method call
 * and logging them because they should never effect ui
 */
export function track(eventName, trackingData) {
  try {
    trackEvent({ ctaName: eventName, properties: trackingData });
  } catch (e) {
    console.log(e);
  }
}

function getData(params) {
  var data = {};
  if (typeof params != 'undefined' && params.hasOwnProperty('data')) {
    data = params['data'];
  }
  return data;
}

export function clickedElement(params) {
  try {
    var dataParams = getData(params);
    if (
      typeof params != 'undefined' &&
      params.hasOwnProperty('target_element')
    ) {
      trackElement({ elementName: params['target_element'], properties: dataParams });
    } else {
      trackElement({ elementName: 'Clicked Element', properties: dataParams });
    }
  } catch (e) {
    console.log(e);
  }
}

/*
 *Track user's identify
 */
export function identifyUser(userId, traits, forceUpdate) {
  try {
    if (forceUpdate) {
      identify({ userId, traits });
      return;
    }
    var analyticsCookieValue = cookies.get('ajs_user_id');
    if (
      (analyticsCookieValue === '' ||
        analyticsCookieValue === 'null' ||
        analyticsCookieValue === '0' ||
        analyticsCookieValue !== userId) &&
      typeof traits != 'undefined' &&
      userId > 0
    ) {
      var contexts = getSegmentContextInfo();
      identify({ userId, traits, contexts });
    }
  } catch (e) {
    console.log(e);
  }
}

/*
 * Track page
 */
export function trackPage(name, properties) {
  properties = properties || {};
  trackPageLoad({ pathName: name, properties: properties });
}

function getSegmentContextInfo() {
  var parser = new UAParser();
  var result = parser.getResult();
  var browser = {
    name: result.browser.name,
    version: result.browser.version,
  };
  var screenSize = {
    width: window.screen.width,
    height: window.screen.height,
  };
  var contexts = {
    os: result.os,
    browser: browser,
    screen: screenSize,
  };
  return contexts;
}
