export const VIEW = {
  SET_WIDTH: 'view/set_width',
  SET_MESSAGE: 'view/set_messages',
  SUBMITTING: 'view/submitting',
  SHOW_PROMPT: 'view/show_prompt',
};

export const setViewWidth = (width) => ({
  type: VIEW.SET_WIDTH,
  payload: width,
});

export const setViewMessages = (messages) => ({
  type: VIEW.SET_MESSAGE,
  payload: messages,
});

export const toggleSubmitting = () => ({
  type: VIEW.SUBMITTING,
});

export const showPrompt = (promptMessage) => ({
  type: VIEW.SHOW_PROMPT,
  payload: promptMessage,
});
