import { USER } from '../actions/userActions';

const initialState = {
  username: '',
  password: '',
  password_1: '',
  password_2: '',
};

const userProps = (payload) => (payload && payload.user ? payload.user : {});

const userReducer = (state = initialState, action = {}) => {
  const { payload = {} } = action;
  switch (action.type) {
    case USER.SET_USER_DATA: {
      return {
        ...state,
        ...userProps(payload),
        shippingAddress: payload.shippingAddress,
        billing: payload.billing,
        subscriptions: payload.subscriptions,
        referral: payload.referral,
        select: payload.select,
      };
    }
    case USER.SET_USERNAME: {
      return {
        ...state,
        username: action.payload,
      };
    }
    case USER.SET_PASSWORD: {
      return {
        ...state,
        password: action.payload,
      };
    }
    case USER.SET_PASSWORD_1_RESET_FORM: {
      return {
        ...state,
        password_1: action.payload,
      };
    }
    case USER.SET_PASSWORD_2_RESET_FORM: {
      return {
        ...state,
        password_2: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default userReducer;
