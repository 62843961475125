import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'react-router-redux';
import { BrowserRouter } from 'react-router-dom';
import store, { history } from './store';
import ldRedux from '@svey/ld-redux';
import TagManager from 'react-gtm-module';

import App from './App';
import * as serviceWorker from './serviceWorker';
import { initialize } from '@fff-web/fff-analytics';

import './index.css';

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_CONTAINER_ID,
};
TagManager.initialize(tagManagerArgs);

const target = document.querySelector('#root');

const flags = {
  'show-pwd-reset-required-prompt': false,
  'show-hcaptcha': false,
  'show-hcaptcha-reset-pwd': false,
  'disable-intermediary-page': false,
};

const ldUser = { key: 'login_client', anonymous: true };
ldRedux.init({
  clientSideId: process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_ID,
  dispatch: store.dispatch,
  flags,
  user: ldUser,
});

globalThis.window.FFF = { ...globalThis.window.FFF };
globalThis.window.FFF[process.env.APP_NAME] = {
  version: process.env.APP_VERSION,
  libs: process.env.APP_LIBS,
};

initialize();

render(
  <Provider store={store}>
    <ConnectedRouter store={store} history={history}>
      <BrowserRouter>
        <div>
          <App />
        </div>
      </BrowserRouter>
    </ConnectedRouter>
  </Provider>,
  target
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
