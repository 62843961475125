import { VIEW } from '../actions/viewActions';

const BROWSER_MIN_WIDTH = 900;

/**
 * Tests a view to see if it's a mobile device
 * @param {number} width - the current width of the view
 * @returns {boolean} true if a mobile view
 */
export const isMobileView = (width) => width < BROWSER_MIN_WIDTH;

const initialState = {
  isMobile: window.innerWidth < BROWSER_MIN_WIDTH,
  width: window.innerWidth,
  messages: [],
  submitting: false,
};

const viewReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case VIEW.SET_WIDTH: {
      return {
        ...state,
        isMobile: isMobileView(action.payload),
        width: action.payload,
      };
    }
    case VIEW.SET_MESSAGE: {
      return {
        ...state,
        messages: action.payload,
      };
    }
    case VIEW.SUBMITTING: {
      return {
        ...state,
        submitting: !state.submitting,
      };
    }
    case VIEW.SHOW_PROMPT: {
      return {
        ...state,
        promptMessage: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default viewReducer;
