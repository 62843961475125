import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ValidCookieForm from '../Home/ValidCookieForm';

import './Otp.css';

import { checkAuth, processOtp } from '../../utils/data';
import { isLoop, gotoPageAfterXSecond, validOrigin } from '../../utils/commons';
import env from '../../utils/env';

class Otp extends Component {
  state = {
    isLoggingIn: false,
    otp: null,
    email: '',
    cta: '',
    isLoading: true,
    isValidCookie: false,
    isLooping: true,
    isCloseSuccessMessage: true,
  };
  constructor() {
    super();
    this.timeout = null;
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  componentDidUpdate() {
    //go to the old otp endpoint if the feature flag is on
    if (this.props.disableIntermediaryPage) {
      let newSearchUrl = new URLSearchParams(this.props.location.search);
      let email = newSearchUrl.get('email');
      let otp = newSearchUrl.get('otp');
      let cta = encodeURIComponent(newSearchUrl.get('cta'));
      window.location = `${env.login}?email=${email}&otp=${otp}&cta=${cta}`;
    }
  }

  componentDidMount() {
    //Verify cookie and switch view accordingly
    checkAuth((res) => {
      let newSearchUrl = new URLSearchParams(this.props.location.search);
      let email = newSearchUrl.get('email');
      let otp = newSearchUrl.get('otp');
      let cta = newSearchUrl.get('cta');
      this.setState({
        ...this.state,
        isLoading: false,
        email: email,
        otp: otp,
        cta: cta,
      });
      let loop = true;
      if (!validOrigin(cta)) {
        cta = env.envURL;
      }

      if (res.ok) {
        if (!isLoop()) {
          loop = false;
          gotoPageAfterXSecond({
            goto_url: cta,
          });
        }
      }

      this.setState({
        ...this.state,
        isValidCookie: res.ok,
        isLooping: loop,
      });
    });
  }

  handleOtp = () => {
    this.setState({
      ...this.state,
      isLoggingIn: true,
    });

    processOtp(this.state.email, this.state.otp, this.state.cta).then((res) => {
      this.setState({ ...this.state, isLoggingIn: false });
      if (res.status) {
        switch (res.status) {
          case 200:
            res.json().then((json) => {
              gotoPageAfterXSecond({
                goto_url: json.redirectUrl,
              });
            });
            break;
          case 400:
          case 422:
            gotoPageAfterXSecond({
              goto_url: env.envURL,
            });
            break;
          default:
            gotoPageAfterXSecond({
              goto_url: env.envURL,
            });
        }
      }
    });
  };

  render() {
    const { handleOtp } = this;

    const {
      isLoading,
      isLoggingIn,
      isValidCookie,
      isLooping,
      isCloseSuccessMessage,
    } = this.state;

    return (
      <React.Fragment>
        {isValidCookie ? (
          <ValidCookieForm isLooping={isLooping} />
        ) : (
          <>
            {!isLoading && (
              <div className="otp-page mar-a">
                <div>
                  <div className="otp">
                    <p className="header" data-testid="otp-title">
                      Member Login
                    </p>
                    <p
                      className="title title-padding"
                      data-testid="otp-subtext"
                    ></p>
                    <form>
                      <div className="labelpair">
                        <label
                          htmlFor="password"
                          data-testid="otp-second-title"
                        >
                          Email Address
                          <span>*</span>
                        </label>
                        <input
                          name="email"
                          type="text"
                          value={this.state.email}
                          disabled="disabled"
                        />
                        <input
                          name="email"
                          data-testid="otp-input"
                          type="hidden"
                          value={this.state.email}
                        />
                      </div>
                    </form>
                    <button
                      className="otp-button button-font"
                      data-testid="otp-btn"
                      type="button"
                      disabled={isLoggingIn || !isCloseSuccessMessage}
                      onClick={handleOtp}
                    >
                      {isLoggingIn ? (
                        <>
                          LOGIN...
                          <i className="far fa-spinner fa-spin" />
                        </>
                      ) : (
                        <>LOGIN</>
                      )}
                    </button>
                  </div>
                </div>
                )
              </div>
            )}
          </>
        )}
      </React.Fragment>
    );
  }
}

const mS = (state) => ({
  disableIntermediaryPage: state.launchDarkly.disableIntermediaryPage,
});

const mD = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mS, mD)(Otp);
